@import '@/styles/common.scss';

.el-menu-vertical-demo:not(.el-menu--collapse) {
    height: 100%;
    text-align: left;
    width: 200px;
}

.el-menu--collapse span {
    height: 0px;
    width: 0px;
    display: none;
}

a {
    text-decoration: none;
}

.el-menu-item {
    color: #000000;
}

.el-menu-item.is-active {
    color: var(--el-menu-active-color);
}

.menulist .el-menu-item {
    padding-left: 58px !important;
    width: 100% !important;
}

::-webkit-scrollbar {
    width: 5px;
    height: 1px;
}

::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background: #c8c8c8;
}

::-webkit-scrollbar-thumb:hover {
    background: #9d9d9d;
}

::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #ffffff;
}

.collapse {
    height: 60px;
    line-height: 60px;
    width: 15px;
    background-color: white;
}

.collapse:hover {
    background-color: #f0f2f5;
}
