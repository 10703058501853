@import '@/styles/common.scss';

.el-container {
  padding: 0;
  /*上右下左*/
  /*margin: -7px -4px -25px -8px;*/
  margin: 0;
  height: 100%;
}

.header-avatar {
  float: right;
  width: 250px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.head-menu {
  float: left;
  margin-left: 20px;
  font-size: 16px;
  font-weight: bold;
}

.el-dropdown-link {
  cursor: pointer;
}

.el-icon-arrow-down {
  font-size: 12px;
  color: #409eff;
}

.el-button {
  background-color: #40444d;
  border: none;
  color: #cdced2;
}

.el-header {
  background-color: #ffffff;
  color: gray;
  text-align: center;
  line-height: 60px;
}

.el-aside {
  background-color: #d3dce6;
  color: #333;
  text-align: center;
  line-height: 200px;
}

.el-main {
  background-color: #e9eef3;
  color: #333;
  padding: 0;
}

a {
  text-decoration: none;
}
